import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  new_collection: [],
  checked_items: [],
  collection_update: [],
  collection_users_update: [],
  collections_list: [],
  collections: [],
  collection_priority: [],
  collection_id: {},
  remove_collection: [],
  loading: false,
  error: false,
};

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    addedCollection(state, action) {
      state.new_collection = action.payload;
    },
    checkedCollectionItems(state, action) {
      state.checked_items = action.payload;
    },
    updatedCollection(state, action) {
      state.collection_update = action.payload;
    },
    getCollectionsList(state) {
      state.loading = true;
    },
    getCollectionsListSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.collections_list = action.payload;
      state.collections = action.payload;
    },
    getCollectionsListFailure(state) {
      state.loading = false;
      state.error = true;
      state.collections_list = [];
    },
    filterCollectionsList(state, action) {
      // This action is called in Search component (this component is in UI folder) every Collections' page render
      // to set the collections property to collection_list state
      // Yep, that's kinda weird, I know
      const query = action.payload;

      if (query === '') {
        state.collections = state.collections_list;
      }

      const foundCollections = state.collections_list.filter((collection) => {
        return collection.name
          .toLocaleLowerCase()
          .match(query.toLocaleLowerCase());
      });

      state.collections = foundCollections;
    },
    updatedCollectionPriority(state, action) {
      state.collection_priority = action.payload;
    },
    updatedCollectionId(state, action) {
      state.collection_id = action.payload;
    },
    removedCollection(state, action) {
      const updatedCollectionsList = state.collections_list.filter(
        (collection) => collection.id !== action.payload
      );
      state.remove_collection = updatedCollectionsList;
    },
    updatedCollectionsUsers(state, action) {
      state.loading = false;
      state.error = false;
      state.collection_users_update = action.payload;
    },
  },
});

export const {
  addedCollection,
  checkedCollectionItems,
  updatedCollection,
  getCollectionsList,
  getCollectionsListSuccess,
  getCollectionsListFailure,
  filterCollectionsList,
  updatedCollectionPriority,
  updatedCollectionId,
  removedCollection,
  updatedCollectionsUsers,
} = collectionsSlice.actions;
export default collectionsSlice.reducer;
