import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Grid, Button, Typography } from '@mui/material';

import createDomain from '../../asyncActions/createDomain';

import useValidateDomain from '../../hooks/useValidateDomain';

import TextField from '../text-field/TextField';
import './Domains.scss';

const CreateDomain = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.auth.auth);

  const {
    name,
    nameIsBlur,
    nameError,
    formIsValid,
    formIsSuccess,
    domainData,
    nameHandler,
    blurHandler,
    setName,
    setNameError,
    setFormIsValid,
    setFormIsSuccess,
  } = useValidateDomain();

  const notificationData = {
    isNotification: true,
    type: 'success',
    action: 'create_domain',
    message: `${name} создан`,
  };

  const submitForm = (e) => {
    e.preventDefault();

    setFormIsSuccess(!formIsSuccess);
    setFormIsValid(false);

    dispatch(createDomain(user_id, domainData, notificationData));

    setName('');
    setNameError(' ');
  };

  return (
    <Stack
      component="form"
      sx={{
        height: '100%',
        p: '14px',
        border: '1px solid #ced2da',
        borderRadius: '4px',
      }}
      onSubmit={submitForm}
    >
      <Typography variant="h2" sx={{ color: 'info.main' }}>
        Создать домен
      </Typography>
      <Stack
        direction={{ md: 'row' }}
        alignItems={{ md: 'flex-end' }}
        justifyContent={{ md: 'space-between' }}
        gap={3}
        pt={{ xs: 2, md: '0' }}
        className="domains-add-form-controls"
      >
        <Grid item xs={12} md={7}>
          <TextField
            id="domainName"
            name="domain"
            label="Имя домена"
            value={name}
            placeholder="Ввести имя домена"
            onChange={nameHandler}
            onBlur={blurHandler}
          />
          {nameIsBlur && nameError && (
            <span className="validate-error">{nameError}</span>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          <Button
            type="submit"
            variant="contained"
            disabled={formIsValid ? false : true}
            size="large"
          >
            Создать
          </Button>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default CreateDomain;
