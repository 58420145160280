import { createSlice } from '@reduxjs/toolkit';

import findUsersInOtherGroups from '../utils/findUsersInOtherGroups';

const initialState = {
  tree: [],
  selectedUsers: {},
  groups: [],
  selectedUsersIdsForBackend: [],
  allSelected: [],
  totalUsersCount: 0,
  selectedGroups: {},
  arrayOfSelectedItems: [],
};

// Here is a lot of repetitive blocks of code and nested loops.
// They're need to be refactored.

const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    updateGroups(state, action) {
      state.groups = action.payload;
    },
    treeUpdated(state, action) {
      state.tree = action.payload;
    },
    updateSelectedGroup(state, action) {
      const itemId = action.payload;
      state.selectedGroups[itemId] = null;

      const groupId = +itemId.split('groupId')[1];
      const group = state.groups.find((group) => group.id === groupId);

      group.ad_users.forEach((userId) => {
        const foundUsers = findUsersInOtherGroups(state.groups, userId);

        for (let user in foundUsers) {
          const _groupId = user.match(/\d+/g)[1];

          state.selectedGroups[_groupId] = {
            ...state.selectedGroups[_groupId],
            [user]: null,
          };
        }
      });
    },
    undoSelectedGroup(state, action) {
      const itemId = action.payload;

      const groupId = +itemId.split('groupId')[1];

      for (let user in state.selectedGroups[groupId]) {
        const userId = user.match(/\d+/g)[2];
        delete state.selectedGroups[groupId][user];

        const foundUsers = findUsersInOtherGroups(state.groups, userId);

        for (let foundUser in foundUsers) {
          const _groupId = foundUser.match(/\d+/g)[1];

          delete state.selectedGroups[_groupId][foundUser];
        }
      }
    },
    resetSelectedGroups(state) {
      state.selectedGroups = {};
    },
    parseUserIdsForBackend(state) {
      const userIds = new Set();

      state.arrayOfSelectedItems.forEach((user) => {
        if (!user.includes('groupId')) {
          const parsedId = +user.split('u')[1];

          // Sometimes a parsed id is equal to 0. Research and resolve.
          if (parsedId > 0) {
            userIds.add(parsedId);
          }
        }
      });

      state.selectedUsersIdsForBackend = Array.from(userIds);
    },
    updateUsersOfSelectedGroup(state, action) {
      const selectedGroup = action.payload;

      const userIds = selectedGroup.ad_users.map((userId) => {
        return `d${selectedGroup.domain}-g${selectedGroup.id}-u${userId}`;
      });

      userIds.forEach((userId) => {
        const _userId = +userId.match(/\d+/g)[2];

        const foundUsers = findUsersInOtherGroups(state.groups, _userId);

        for (let user in foundUsers) {
          const _groupId = user.match(/\d+/g)[1];

          state.selectedGroups[_groupId] = {
            ...state.selectedGroups[_groupId],
            [user]: null,
          };
        }

        state.selectedUsers[userId] = null;
      });

      state.totalUsersCount = selectedGroup.ad_users.length;
    },
    undoUsersOfSelectedGroup(state, action) {
      const groupId = +action.payload;

      for (let user in state.selectedGroups[groupId]) {
        const userId = +user.split('-u')[1];

        const foundUsers = findUsersInOtherGroups(state.groups, userId);

        for (let foundUser in foundUsers) {
          const _groupId = foundUser.match(/\d+/g)[1];

          delete state.selectedGroups[_groupId][foundUser];
        }
      }
    },
    selectAllUsersOfSelectedGroup(state, action) {
      const groupId = +action.payload;

      const foundGroup = state.groups.find((group) => group.id === groupId);

      foundGroup.ad_users.forEach((userId) => {
        const foundUsers = findUsersInOtherGroups(state.groups, userId);

        for (let user in foundUsers) {
          const _groupId = user.match(/\d+/g)[1];

          state.selectedGroups[_groupId] = {
            ...state.selectedGroups[_groupId],
            [user]: null,
          };
        }
      });
    },
    updateSelectedGroups(state, action) {
      const userId = action.payload.match(/\d+/g)[2];

      const foundUsers = findUsersInOtherGroups(state.groups, userId);

      for (let user in foundUsers) {
        const _groupId = user.match(/\d+/g)[1];

        state.selectedGroups[_groupId] = {
          ...state.selectedGroups[_groupId],
          [user]: null,
        };
      }
    },
    undoUsers(state, action) {
      const userId = action.payload.match(/\d+/g)[2];

      const foundUsers = findUsersInOtherGroups(state.groups, userId);

      for (let user in foundUsers) {
        const _groupId = user.match(/\d+/g)[1];

        delete state.selectedGroups[_groupId][user];
      }
    },
    createArrayFromSelectedGroups(state) {
      const arrayOfSelectedItems = Object.values(state.selectedGroups).reduce(
        (acc, current) => {
          for (let item in current) {
            acc.push(item);
          }

          return acc;
        },
        []
      );

      state.arrayOfSelectedItems = arrayOfSelectedItems;
    },
  },
});

export const {
  updateGroups,
  treeUpdated,
  updateSelectedGroup,
  undoSelectedGroup,
  resetSelectedGroups,
  parseUserIdsForBackend,
  selectAll,
  updateUsersOfSelectedGroup,
  undoUsersOfSelectedGroup,
  selectAllUsersOfSelectedGroup,
  updateSelectedGroups,
  setSelected,
  undoUsers,
  createArrayFromSelectedGroups,
} = treeSlice.actions;
export default treeSlice.reducer;
