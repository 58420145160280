import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profiles: [],
  loading: false,
  error: false,
};

const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    getProfiles(state) {
      state.loading = true;
    },
    getProfilesSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.profiles = action.payload;
    },
    getProfilesFailure(state) {
      state.loading = false;
      state.error = true;
      state.profiles = [];
    },
  },
});

export const { getProfiles, getProfilesSuccess, getProfilesFailure } =
  profilesSlice.actions;
export default profilesSlice.reducer;
