import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import Icon from '../../Icon';

const ArrowButton = ({ url, title }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
      <Button
        component={NavLink}
        to={url}
        startIcon={<Icon id="go-back" width="20" height="20" />}
      >
        <Typography
          variant="h2"
          component="span"
          sx={{ lineHeight: '24px', color: 'info.main' }}
        >
          {title}
        </Typography>
      </Button>
    </Box>
  );
};

export default ArrowButton;
