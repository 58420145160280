export default function findUsersInOtherGroups(GROUPS, userId) {
  const itemsToCheckState = {};

  GROUPS.forEach((group) => {
    if (group.ad_users.includes(+userId)) {
      const idForCheckedState = `d${group.domain}-g${group.id}-u${userId}`;

      itemsToCheckState[idForCheckedState] = null;
    }
  });

  return itemsToCheckState;
}
