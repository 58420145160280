import { api } from '../api/httpClient';

import { removedCollection } from '../store/collectionsReducer';
import { notificationSuccess } from '../store/notificationReducer';

const removeCollection = (userId, collectionId, notificationData) => {
  return function (dispatch) {
    api
      .delete(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/collections/${collectionId}/`,
        {
          user_id: userId,
          collection_id: collectionId,
        }
      )
      .then(() => {
        dispatch(removedCollection(collectionId));
        dispatch(notificationSuccess(notificationData));
      });
  };
};

export default removeCollection;
