import { api } from '../api/httpClient';

import { updatedDomain } from '../store/domainsReducer';
import { notificationSuccess } from '../store/notificationReducer';

const updateDomain = (userId, domainData, domainName, notificationData) => {
  return function (dispatch) {
    api
      .patch(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/agent_domains/${domainData.id}/`,
        { name: domainName }
      )
      .then((response) => {
        dispatch(updatedDomain(response.data.name));
        dispatch(notificationSuccess(notificationData));
      })
      .catch((error) => {
        console.log({ error });
      });
  };
};

export default updateDomain;
