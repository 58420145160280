import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import updatePriority from '../../asyncActions/updatePriority';

import usePagination from '../../hooks/usePagination';
import Paginator from '../UI/paginator/Paginator';
import CollectionsListItem from './CollectionsListItem';
import RemoveCollection from './RemoveCollection';
import FetchError from '../UI/fetch-error/FetchError';
import { TableHeadCell } from '../table-cells/TableCells';

import './Collections.scss';

const initialDragAndDropState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
};

const CollectionsList = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.auth.auth);
  const { error } = useSelector((state) => state.collections);
  const collections = useSelector((state) => state.collections.collections);
  const [dragAndDrop, setDragAndDrop] = useState(initialDragAndDropState);
  const [collectionsList, setCollectionsList] = useState(collections);
  const priority = useRef();

  useEffect(() => {
    const sortedCollections = [...collections].sort(
      (a, b) => a.priority - b.priority
    );

    setCollectionsList(sortedCollections);
  }, [collections]);

  useEffect(() => {
    priority.current = collectionsList.map((item, index) => {
      return {
        collection_id: item.id,
        priority: index + 1,
      };
    });
  }, [collectionsList]);

  const changePriorityHandler = () => {
    dispatch(updatePriority(user_id, priority.current));
  };

  const onDragStart = (e) => {
    const initialPosition = Number(e.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: collectionsList,
    });

    e.dataTransfer.setData('text/html', '');
  };

  const onDragOver = (e) => {
    e.preventDefault();

    let newList = dragAndDrop.originalOrder;

    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(e.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (_item, index) => index !== draggedFrom
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop = () => {
    setCollectionsList(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  const itemsCount = 5;
  const { page, gaps, totalPages, nextPage, prevPage, setPage } = usePagination(
    {
      contentPerPage: itemsCount,
      count: collectionsList && collectionsList.length,
    }
  );

  const collectionItem = (
    id,
    priority,
    name,
    policy,
    users,
    dataposition,
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    changePriorityHandler
  ) => {
    return (
      <CollectionsListItem
        key={id}
        id={id}
        priority={priority}
        name={name}
        policy={policy}
        users={users}
        dataposition={dataposition}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onChangePriority={changePriorityHandler}
      />
    );
  };

  if (error) {
    return <FetchError />;
  }

  if (collectionsList.length === 0) {
    return <p className="collections-list-empty">Коллекции не найдены</p>;
  }

  return (
    <React.Fragment>
      <TableContainer sx={{ pt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell align="center">
                <Typography variant="h2" component="span">
                  Приоритет
                </Typography>
              </TableHeadCell>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Коллекции
                </Typography>
              </TableHeadCell>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Профиль
                </Typography>
              </TableHeadCell>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Пользователи
                </Typography>
              </TableHeadCell>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collectionsList?.map((listItem, index) =>
              collectionItem(
                listItem.id,
                listItem.priority,
                listItem.name,
                listItem.profile,
                listItem.ad_users.length,
                index,
                onDragStart,
                onDragOver,
                onDrop,
                onDragLeave,
                changePriorityHandler
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {collectionsList.length > itemsCount && (
        <Paginator
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
          gaps={gaps}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}

      <RemoveCollection />
    </React.Fragment>
  );
};

export default CollectionsList;
