export function getWordEnding(count) {
  const cases = ['пользователь', 'пользователя', 'пользователей'];
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  // Check last two digit (from 11 to 19)
  if (lastTwoDigits > 10 && lastTwoDigits < 20) {
    return count + ' ' + cases[2];
  }

  switch (lastDigit) {
    case 1:
      return count + ' ' + cases[0]; // "пользователь"
    case 2:
    case 3:
    case 4:
      return count + ' ' + cases[1]; // "пользователя"
    default:
      return count + ' ' + cases[2]; // "пользователей"
  }
}
