import { api } from '../api/httpClient';

import { addedDomain } from '../store/domainsReducer';
import { notificationSuccess } from '../store/notificationReducer';

const createDomain = (userId, newDomain, notificationData) => {
  return function (dispatch) {
    api
      .post(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/agent_domains/`,
        newDomain
      )
      .then((response) => {
        dispatch(addedDomain(response.data));
        dispatch(notificationSuccess(notificationData));
      })
      .catch((error) => {
        console.log({ error });
      });
  };
};

export default createDomain;
