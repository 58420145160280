import { useState } from 'react';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Drawer,
} from '@mui/material';

import Menu from '../menu/Menu';

import logo from '../../icons/m-logo.svg';
import navMenu from '../../icons/nav-menu.svg';
import close from '../../icons/close.svg';

import styles from './AppBar.module.scss';

const AppBar = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <MuiAppBar classes={{ root: styles['MuiToolbar-root'] }}>
      <Toolbar>
        <img src={logo} width={30} height={40} alt="mobile logo" />
        <IconButton
          aria-label="menu"
          variant="outlined"
          classes={{ root: styles['MuiIconButton-root'] }}
          onClick={toggleDrawer(!open)}
        >
          <img src={open ? close : navMenu} alt="nav menu" />
        </IconButton>
      </Toolbar>
      <Drawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        classes={{
          root: styles['MuiDrawer-root'],
          paper: styles['MuiPaper-root'],
        }}
      >
        <Menu />
      </Drawer>
    </MuiAppBar>
  );
};

export default AppBar;
