import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Divider, Box, Typography } from '@mui/material';

import Menu from '../menu/Menu';

import logo from '../../icons/Logo.svg';

import styles from '../menu/Menu.module.scss';

const Navbar = () => {
  const authData = useSelector((state) => state.auth.auth);

  return (
    <Grid item className={styles['navbar']}>
      <img
        src={logo}
        className={styles['logo']}
        width={170}
        height={34}
        alt="logo"
      />
      <Divider />
      <Box
        sx={{
          mt: 4,
          p: '16px 95px 16px 12px',
          backgroundColor: 'secondary.dark',
          borderRadius: '10px 0 0 10px',
        }}
      >
        <Typography variant="h3">{authData.username}</Typography>
        {authData.contract ? (
          <Typography variant="caption">{authData.contract}</Typography>
        ) : null}
      </Box>
      <Menu />
    </Grid>
  );
};

export default Navbar;
