import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Dialog, Grid, Typography } from '@mui/material';

import Notification from '../UI/notification/Notification';

import { getWordEnding } from '../../utils/getWordEnding';

const SubmitBar = ({
  isFormValid,
  buttonText,
  onSubmit,
  dialogState,
  dialogAction,
}) => {
  const arrayOfSelectedItems = useSelector(
    (state) => state.tree.arrayOfSelectedItems
  );

  return (
    <>
      <div className="submit-bar">
        Выбрано: {getWordEnding(arrayOfSelectedItems.length)}
        {isFormValid ? (
          <Button variant="outlined" onClick={() => dialogAction(true)}>
            Отмена
          </Button>
        ) : (
          <Button variant="outlined" to="/collections" component={NavLink}>
            Отмена
          </Button>
        )}
        <Button
          variant="contained"
          disabled={!isFormValid}
          size="small"
          onClick={onSubmit}
        >
          {buttonText}
        </Button>
      </div>

      <Notification />

      <Dialog open={dialogState}>
        <Grid container>
          <Typography variant="h3">Отменить создание коллекции?</Typography>
        </Grid>
        <Typography variant="body1" sx={{ pt: 3, pb: 5 }}>
          Все изменения не будут сохранены
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              sx={{ width: '100%' }}
              onClick={() => dialogAction(false)}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              to="/collections"
              variant="contained"
              component={NavLink}
              sx={{ width: '100%' }}
            >
              Продолжить
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default SubmitBar;
