import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Dialog, Box, Typography } from '@mui/material';

import ModalContext from '../../contexts/modal-context';
import removeCollection from '../../asyncActions/removeCollection';

import { ReactComponent as TrashcanIcon } from '../../icons/trashcan.svg';

import './Collections.scss';

const RemoveCollection = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.auth.auth);
  const collectionData = useSelector(
    (state) => state.collections.collection_id
  );
  const { isShowRemoveModal, setIsShowRemoveModal } = useContext(ModalContext);

  const notificationData = {
    isNotification: true,
    type: 'success',
    action: 'remove_domain',
    message: `${collectionData.collection_name} was successfully deleted`,
  };

  const onToggleModal = () => {
    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  const deleteCollectionHandler = () => {
    dispatch(removeCollection(user_id, collectionData.id, notificationData));
    setIsShowRemoveModal(!isShowRemoveModal);
  };

  return (
    <Dialog open={isShowRemoveModal}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          backgroundColor: 'warning.light',
        }}
      >
        <TrashcanIcon />
      </Box>
      <Typography
        variant="subtitle2"
        component="h3"
        sx={{ pt: 3, pb: 5, textAlign: 'left', color: 'info.main' }}
      >
        Вы уверены что хотите удалить коллекцию
        <br /> "{collectionData.collection_name}"?
      </Typography>
      <Grid container gap={2} className="domains-list-remove-controls">
        <Button variant="contained" onClick={onToggleModal} size="small">
          Отмена
        </Button>
        <Button
          variant="outlined"
          onClick={deleteCollectionHandler}
          size="small"
          sx={{
            flex: '1 0',
            color: '#fff',
          }}
        >
          Удалить
        </Button>
      </Grid>
    </Dialog>
  );
};

export default RemoveCollection;
