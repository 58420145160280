import { api } from '../api/httpClient';

import { removedDomain } from '../store/domainsReducer';
import { notificationSuccess } from '../store/notificationReducer';

const removeDomain = (userId, domainData, notificationData) => {
  return function (dispatch) {
    api
      .delete(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/agent_domains/${domainData.id}/`,
        domainData
      )
      .then(() => {
        dispatch(removedDomain(domainData.id));
        dispatch(notificationSuccess(notificationData));
      })
      .catch((error) => {
        console.log({ error });
      });
  };
};

export default removeDomain;
