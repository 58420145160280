import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Paper,
  Grid,
  Button,
  Dialog,
  Typography,
  Box,
  Stack,
} from '@mui/material';

import ModalContext from '../../contexts/modal-context';

import updateCollection from '../../asyncActions/updateCollection';
import { getWordEnding } from '../../utils/getWordEnding';

import TextField from '../text-field/TextField';
import Select from '../select/Select';
import ArrowButton from '../UI/go-back/GoBack';
import CollectionsConfigurate from './CollectionsConfigurate';
import SubmitBar from './SubmitBar';

import success from '../../icons/success.svg';

import './Collections.scss';
import appStyles from '../app/App.module.scss';

const CollectionsEditForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user_id } = useSelector((state) => state.auth.auth);
  const profilesList = useSelector((state) => state.profiles.profiles);
  const selectedUserIds = useSelector(
    (state) => state.tree.selectedUsersIdsForBackend
  );
  const returnCollection = useMemo(() => {
    return (collectionList) => {
      return collectionList.filter((e) => e.id.toString() === id)[0];
    };
  }, [id]);

  const collectionList = useSelector((state) =>
    returnCollection(state.collections.collections_list)
  );

  const { isShowCollectionModal, setIsShowCollectionModal } =
    useContext(ModalContext);

  const [name, setName] = useState(collectionList && collectionList.name);
  const [nameIsBlur, setNameIsBlur] = useState(false);
  const [nameError, setNameError] = useState(
    'Collection name field is required'
  );
  const [policy, setPolicy] = useState(
    collectionList && collectionList.profile
  );
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [isFormValid, setFormState] = useState(false);
  const [formIsSuccess, setFormIsSuccess] = useState(false);
  const [updateCollectionData, setCreateCollectionData] = useState({
    plan_code: 'RESELLER',
    priority: collectionList && collectionList.priority,
    profile: collectionList && collectionList.profile,
    domains: [],
    groups: [],
    ad_users: collectionList && collectionList.ad_users,
  });
  const [submitBarDialogState, setSubmitBarDialogState] = useState(false);

  useEffect(() => {
    collectionList && setCheckedUsers(collectionList.ad_users);
  }, [collectionList]);

  useEffect(() => {
    nameError ? setFormState(false) : setFormState(true);
  }, [nameError]);

  useEffect(() => {
    setCreateCollectionData({
      ...updateCollectionData,
      ad_users: selectedUserIds,
    });
    setFormState(true);

    // eslint-disable-next-line
  }, [selectedUserIds]);

  const nameHandler = (e) => {
    setName(e.target.value);

    if (!e.target.value) {
      setNameError('Name field is required');
    } else {
      setNameError('');
      setCreateCollectionData({
        ...updateCollectionData,
        name: e.target.value,
      });
    }
  };

  const policyHandler = (e) => {
    setPolicy(e.target.value);

    setCreateCollectionData({
      ...updateCollectionData,
      profile: e.target.value,
    });
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name':
        setNameIsBlur(true);
        break;
      default:
        break;
    }
  };

  const sumbitForm = (e) => {
    e.preventDefault();

    setFormIsSuccess(!formIsSuccess);
    setFormState(false);

    dispatch(updateCollection(user_id, id, updateCollectionData));
    setIsShowCollectionModal(!isShowCollectionModal);

    setNameError(' ');
  };

  const onToggleCollectionModal = () => {
    navigate('/collections');
    return setIsShowCollectionModal(false);
  };

  const successCircleCls = appStyles['circle'] + ' ' + appStyles['success'];

  return (
    collectionList && (
      <div className="collections-form-wrapper">
        <ArrowButton
          url="/collections"
          direction="left"
          title="Создание новой коллекции"
        />
        <Paper sx={{ position: 'relative' }}>
          <Grid container spacing={1} pb={3} component="form">
            <Grid item xs={12} md={3}>
              <TextField
                value={name}
                size="small"
                placeholder="Название коллекции"
                onChange={nameHandler}
                onBlur={blurHandler}
              />
              {nameIsBlur && nameError && (
                <span className="validate-error">{nameError}</span>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Select value={policy} onChange={policyHandler}>
                {profilesList.map((option) => (
                  <option key={Math.random()} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </Grid>
          </Grid>

          <CollectionsConfigurate
            collectionName={name}
            checkedUsers={checkedUsers}
          />

          <SubmitBar
            isFormValid={isFormValid}
            onSubmit={sumbitForm}
            buttonText="Сохранить коллекцию"
            dialogState={submitBarDialogState}
            dialogAction={setSubmitBarDialogState}
          />
        </Paper>

        <Dialog open={isShowCollectionModal}>
          <Box className={successCircleCls}>
            <img
              src={success}
              width={28}
              height={28}
              alt="Collection Success"
            />
          </Box>
          <Typography variant="h2" component="h3" sx={{ pt: 3, pb: 1 }}>
            Коллекция изменена
          </Typography>
          <Stack component="ul" sx={{ gap: '10px', m: 0, pl: 2, pb: 3 }}>
            <Typography variant="body1" component="li">
              {name}
            </Typography>
            <Typography variant="body1" component="li">
              {policy}
            </Typography>
            <Typography variant="body1" component="li">
              {getWordEnding(selectedUserIds.length)}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            size="small"
            onClick={onToggleCollectionModal}
          >
            Закрыть
          </Button>
        </Dialog>
      </div>
    )
  );
};

export default CollectionsEditForm;
