import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  create_auth_key: {},
  auth_key: {},
  new_user: [],
  users_list: [],
  filteredUsersFromApi: [],
  search: '',
  assigned: {
    data: [],
  },
  loading: false,
  loadingUsers: false,
  error: false,
  authKeyError: false,
  totalPagesCount: 1,
  initialUsersSize: 0,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // TODO: update auth key with action.payload maybe
    createdAuthKey(state, authKey) {
      state.create_auth_key = authKey;
    },
    addedUser(state, action) {
      state.new_user = action.payload;
    },
    getAuthKey(state) {
      state.loading = true;
    },
    getAuthKeySuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.auth_key = action.payload;
    },
    getAuthKeyFailure(state) {
      state.loading = false;
      state.authKeyError = true;
      state.auth_key = {};
    },
    getUsersList(state) {
      state.loading = true;
      state.error = false;
      state.users_list = [];
    },
    getUsersListSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.users_list = action.payload;
      state.initialUsersSize = action.payload.length;
    },
    getUsersListFailure(state) {
      state.loading = false;
      state.error = true;
      state.users_list = [];
    },
    filteredUsersList(state, action) {
      state.search = action.payload;
    },
    assignedUsersList(state, action) {
      state.assigned = action.payload;
    },
    foundUser(state, action) {
      state.search = action.payload;
    },
    updatedTotalPagesCount(state, action) {
      state.totalPagesCount = action.payload;
    },
    triggerUsersLoadingOnScroll(state) {
      state.loadingUsers = true;
    },
    updatedUsersOnScroll(state, action) {
      state.users_list = [...state.users_list, ...action.payload];
      state.loadingUsers = false;
    },
    reducedUsersToTheirInitialSize(state) {
      state.users_list = state.users_list.slice(0, state.initialUsersSize);
    },
    setFilteredUsersFromApi(state, action) {
      state.filteredUsersFromApi = action.payload;
    },
  },
});

export const {
  createdAuthKey,
  addedUser,
  getAuthKey,
  getAuthKeySuccess,
  getAuthKeyFailure,
  getUsersList,
  getUsersListSuccess,
  getUsersListFailure,
  filteredUsersList,
  assignedUsersList,
  foundUser,
  updatedTotalPagesCount,
  updatedUsersOnScroll,
  reducedUsersToTheirInitialSize,
  setFilteredUsersFromApi,
  triggerUsersLoadingOnScroll,
} = usersSlice.actions;
export default usersSlice.reducer;
