import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { TableRow, IconButton, Typography } from '@mui/material';

import { updatedDomainId } from '../../store/domainsReducer';
import ModalContext from '../../contexts/modal-context';

import { TableBodyCell } from '../table-cells/TableCells';
import Icon from '../Icon';

import './Domains.scss';
import appStyles from '../app/App.module.scss';

const DomainsListItem = ({ id, domain, groups, users }) => {
  const dispatch = useDispatch();
  const {
    isShowRemoveModal,
    setIsShowRemoveModal,
    isShowUpdateModal,
    setIsShowUpdateModal,
  } = useContext(ModalContext);

  const onToggleRemoveModal = () => {
    dispatch(
      updatedDomainId({
        id: id,
        domain_name: domain,
      })
    );

    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  const onToggleUpdateModal = () => {
    dispatch(
      updatedDomainId({
        id: id,
        domain_name: domain,
      })
    );

    return setIsShowUpdateModal(!isShowUpdateModal);
  };

  return (
    <TableRow
      key={id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableBodyCell scope="row">
        <Typography variant="body1" component="span">
          {domain}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {groups}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {users}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="center" sx={{ width: '50px', p: '16px 0' }}>
        <IconButton onClick={onToggleUpdateModal}>
          <Icon
            id="edit"
            width="22"
            height="22"
            className={appStyles['icon']}
          />
        </IconButton>
      </TableBodyCell>
      <TableBodyCell align="center" sx={{ width: '50px', p: '16px 0' }}>
        <IconButton onClick={onToggleRemoveModal}>
          <Icon
            id="remove"
            width="17"
            height="18"
            className={appStyles['icon']}
          />
        </IconButton>
      </TableBodyCell>
    </TableRow>
  );
};

export default DomainsListItem;
