import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputBase } from '@mui/material';

import { setFilteredUsersFromApi } from '../../store/usersReducer';
import { fetchFilteredUsersList } from '../../asyncActions/fetchFilteredUsers';

const Search = ({ value, userId, groupId, placeholder, onChange, sx }) => {
  const dispatch = useDispatch();
  const [debouncedText, setDebouncedText] = useState(value);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(value);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [value]);

  useEffect(() => {
    if (value === '') {
      dispatch(setFilteredUsersFromApi([]));
    }

    if (userId && groupId && value) {
      dispatch(fetchFilteredUsersList(userId, groupId, value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <InputBase
      placeholder={placeholder}
      value={value}
      size="small"
      onChange={onChange}
      sx={{
        '& input': { p: 0 },
        ...sx,
        height: '100%',
      }}
    />
  );
};

export default Search;
