import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cabinet_menu: [],
  loading: false,
  error: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    getCabinetMenu(state) {
      state.loading = true;
    },
    getCabinetMenuSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.cabinet_menu = action.payload;
    },
    getCabinetMenuFailure(state) {
      state.loading = false;
      state.error = true;
      state.cabinet_menu = [];
    },
  },
});

export const { getCabinetMenu, getCabinetMenuSuccess, getCabinetMenuFailure } =
  menuSlice.actions;
export default menuSlice.reducer;
