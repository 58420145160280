import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, Stack, Grid, Button, Typography, Box } from '@mui/material';
import ModalContext from '../../contexts/modal-context';

import removedDomain from '../../asyncActions/removeDomain';

import { ReactComponent as TrashcanIcon } from '../../icons/trashcan.svg';

const RemoveDomain = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.auth.auth);
  const domainData = useSelector((state) => state.domains.domain_id);
  const { isShowRemoveModal, setIsShowRemoveModal } = useContext(ModalContext);

  const notificationData = {
    isNotification: true,
    type: 'success',
    action: 'remove_domain',
    message: `${domainData.domain_name} удален`,
  };

  const onToggleModal = () => {
    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  const removeDomainHandler = () => {
    dispatch(removedDomain(user_id, domainData, notificationData));
    setIsShowRemoveModal(!isShowRemoveModal);
  };

  return (
    <Dialog open={isShowRemoveModal}>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: 'warning.light',
          }}
        >
          <TrashcanIcon />
        </Box>
        <Typography
          variant="h3"
          component="h2"
          sx={{ textAlign: 'left', pt: 3, color: 'info.main' }}
        >
          Вы уверены, что хотите удалить
          <br />
          &nbsp;"{domainData.domain_name}
          "?
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ textAlign: 'left', pt: 1, pb: 5, color: 'info.main' }}
        >
          Пользователи и коллекции, связанные с этим доменом, также будут
          удалены.
        </Typography>
        <Grid container sx={{ pt: '12px', gap: 3 }}>
          <Button
            variant="contained"
            onClick={onToggleModal}
            sx={{ flex: '1 0' }}
          >
            Отмена
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={removeDomainHandler}
            sx={{
              flex: '1 0',
              color: '#fff',
            }}
          >
            Удалить
          </Button>
        </Grid>
      </Stack>
    </Dialog>
  );
};

export default RemoveDomain;
