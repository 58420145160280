import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Grid, Button, Typography } from '@mui/material';
import { createdAuthKey } from '../../store/usersReducer';
import Tooltip from '../tooltip/Tooltip';

import './Domains.scss';

const ConfigurateAd = () => {
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);
  const isAuthKeyAd = useSelector((state) => state.users.auth_key);
  const domainsList = useSelector((state) => state.domains.domains_list);

  useEffect(() => {
    if (domainsList) {
      if (!isAuthKeyAd.length && domainsList.length >= 1) {
        onAuthKeyCreate();
      }
    }
  }, [domainsList]);

  const onAuthKeyCreate = () => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${isAuthData.user_id}/ad_auth_key/`,
      withCredentials: true,
      data: {
        user_id: isAuthData.user_id,
      },
    })
      .then((response) => {
        dispatch(createdAuthKey(response.data));

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.detail);
      });
  };

  // p: '14px', border: '1px solid #ced2da', borderRadius: '4px'

  return (
    <Stack
      className="domains-configurate-ad"
      sx={{ p: '14px', border: '1px solid #ced2da', borderRadius: '4px' }}
    >
      <Typography variant="h2" sx={{ pb: 3, color: 'info.main' }}>
        Настроить Active Directory
      </Typography>
      <Typography variant="body2" component="p" sx={{ color: 'info.main' }}>
        Следуйте инструкциям по настройке Active Directory и загрузке агента.
      </Typography>
      <Stack
        gap={1}
        direction={{ md: 'row' }}
        className="domains-add-form-controls"
        pt={{ xs: 3 }}
      >
        <Grid item xs={12} md={6}>
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="h6" component="h6" color="info.main">
                  Генерация ключа{' '}
                </Typography>
                <Typography variant="caption" color="main.info">
                  Agent будет доступен для скачивания после завершения генерации
                  ключа
                </Typography>
              </React.Fragment>
            }
          >
            <span>
              <Button
                variant="contained"
                size="large"
                disabled={isAuthKeyAd.length ? false : true}
              >
                Загрузить агент
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            size="large"
            href="https://docs.safedns.com/books/active-directory-agent-installation/page/setting-up-customer-in-the-activedirectory-section"
            target="_blank"
          >
            Инструкция
          </Button>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ConfigurateAd;
