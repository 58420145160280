import {
  getUsersList,
  getUsersListSuccess,
  getUsersListFailure,
  updatedTotalPagesCount,
} from '../store/usersReducer';

import { api } from '../api/httpClient';

export const fetchUsersList = (userId) => {
  return function (dispatch) {
    dispatch(getUsersList());

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/ad_users_with_profiles/`
      )
      .then((response) => {
        dispatch(getUsersListSuccess(response.data.results));
        dispatch(updatedTotalPagesCount(response.data.total_pages));
      })
      .catch(() => {
        dispatch(getUsersListFailure());
      });
  };
};
