import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  new_group: [],
  groups_list: [],
  search: [],
  loading: false,
  error: false,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    groupAdded(state, action) {
      state.new_group = action.payload;
    },
    getGroupsList(state) {
      state.loading = true;
    },
    getGroupsListSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.groups_list = action.payload;
    },
    getGroupsListFailure(state, action) {
      state.loading = false;
      state.error = true;
      state.groups_list = action.payload;
    },
  },
});

export const {
  groupAdded,
  getGroupsList,
  getGroupsListSuccess,
  getGroupsListFailure,
} = groupsSlice.actions;
export default groupsSlice.reducer;
