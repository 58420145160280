import { updateUsersOfSelectedGroup } from '../store/treeReducer';

import { api } from '../api/httpClient';

export const fetchAllUsersOfSelectedGroup = (userId, groupId) => {
  return function (dispatch) {
    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/ad_groups/${groupId}`
      )
      .then((response) => {
        dispatch(updateUsersOfSelectedGroup(response.data));
      });
  };
};
