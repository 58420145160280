import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  new_domain: [],
  domains_list: [],
  domain_id: {},
  update_domain: [],
  remove_domain: [],
  search: [],
  loading: false,
  error: false,
};

const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    addedDomain(state, action) {
      state.new_domain = action.payload;
    },
    getDomainsList(state) {
      state.loading = true;
      state.error = false;
      state.domains_list = [];
    },
    getDomainsListSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.domains_list = action.payload;
    },
    getDomainsFailureFailure(state) {
      state.loading = false;
      state.error = true;
      state.domains_list = [];
    },
    filteredDomainsList(state, action) {
      state.search = action.payload;
    },
    updatedDomainId(state, action) {
      state.domain_id = action.payload;
    },
    removedDomain(state, action) {
      const updatedDomainsList = state.domains_list.filter(
        (domain) => domain.id !== action.payload
      );
      state.remove_domain = updatedDomainsList;
    },
    updatedDomain(state, action) {
      state.update_domain = action.payload;
    },
  },
});

export const {
  addedDomain,
  getDomainsList,
  getDomainsListSuccess,
  getDomainsListFailure,
  filteredDomainsList,
  updatedDomainId,
  removedDomain,
  updatedDomain,
} = domainsSlice.actions;
export default domainsSlice.reducer;
