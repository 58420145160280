// Create a map of users to easily assign each one to its group
export function createUsersMap(users) {
  let map = {};

  users.forEach((user) => {
    map[user.id] = {
      ...user,
      label: user.name,
    };
  });

  return map;
}

export function createGroupTree(groups, parent, usersMap) {
  const result = [];

  groups
    .filter((group) => group.parent === parent)
    .forEach((group) => {
      const _group = { ...group };

      _group.children = [];
      _group.value = `d${_group.domain}-groupId${_group.id}`;

      _group.ad_users.forEach((user) => {
        // If you don't create a copy, there'll bug with duplicating ids
        // because down below we need to create an id for each user
        const foundUser = { ...usersMap[user] };

        if (foundUser.id) {
          foundUser.value = `d${_group.domain}-g${_group.id}-u${foundUser.id}`;
          _group.children.push(foundUser);
        }
      });

      const groupTree = createGroupTree(groups, _group.id, usersMap);

      result.push({
        ..._group,
        label: _group.name,
        children: groupTree.concat(_group.children),
      });
    });

  return result;
}

export function createTree(domains, groupsTree) {
  const result = [];

  domains.forEach((domain) => {
    result.push({
      ...domain,
      value: crypto.randomUUID(),
      label: domain.name,
      children: groupsTree.filter((group) => group.domain === domain.id),
    });
  });

  return result;
}
