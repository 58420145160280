import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import cabinetMenuReducer from './cabinetMenuReducer';
import profilesReducer from './profilesReducer';
import usersReducer from './usersReducer';
import domainsReducer from './domainsReducer';
import collectionsReducer from './collectionsReducer';
import groupsReducer from './groupsReducer';
import notificationReducer from './notificationReducer';
import treeReducer from './treeReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cabinetMenu: cabinetMenuReducer,
    profiles: profilesReducer,
    users: usersReducer,
    domains: domainsReducer,
    collections: collectionsReducer,
    groups: groupsReducer,
    notification: notificationReducer,
    tree: treeReducer,
  },
});
