import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { TableRow, Tooltip, Typography, IconButton } from '@mui/material';

import { updatedCollectionId } from '../../store/collectionsReducer';
import ModalContext from '../../contexts/modal-context';
import { uid } from '../../uid';

import { TableBodyCell } from '../table-cells/TableCells';
import Icon from '../Icon';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import appStyles from '../app/App.module.scss';

const CollectionsListItem = ({
  id,
  priority,
  name,
  policy,
  users,
  dataposition,
  onDragStart,
  onDragOver,
  onDrop,
  onDragLeave,
  onChangePriority,
}) => {
  const dispatch = useDispatch();
  const profilesList = useSelector((state) => state.profiles.profiles);
  const { isShowRemoveModal, setIsShowRemoveModal } = useContext(ModalContext);

  const onToggleRemoveModal = () => {
    dispatch(
      updatedCollectionId({
        id: id,
        collection_name: name,
      })
    );

    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  return (
    <TableRow
      key={id}
      draggable
      data-position={dataposition}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      onDragEnd={onChangePriority}
    >
      <TableBodyCell scope="row">
        <Tooltip
          arrow
          placement="right"
          title={
            <Typography variant="caption" component="span">
              Удерживайте значок и перетащите, чтобы изменить приоритет
              коллекции.
            </Typography>
          }
        >
          <DragIndicatorIcon className="drag-icon" />
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell align="center">
        <Typography variant="body1" component="span">
          {priority}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {name}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        {profilesList.map((profile) =>
          profile.id === policy ? (
            <Typography key={uid()} variant="body1" component="span">
              {profile.name}
            </Typography>
          ) : (
            ''
          )
        )}
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {users}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="center" sx={{ width: '50px', p: '16px 0' }}>
        <NavLink to={id.toString()}>
          <IconButton className={appStyles['MuiIconButton-root']}>
            <Icon
              id="edit"
              width="22"
              height="22"
              className={appStyles['icon']}
            />
          </IconButton>
        </NavLink>
      </TableBodyCell>
      <TableBodyCell align="center" sx={{ width: '50px', p: '16px 0' }}>
        <IconButton
          className={appStyles['MuiIconButton-root']}
          onClick={onToggleRemoveModal}
        >
          <Icon
            id="remove"
            width="18"
            height="18"
            className={appStyles['icon']}
          />
        </IconButton>
      </TableBodyCell>
    </TableRow>
  );
};

export default CollectionsListItem;
