import { api } from '../api/httpClient';

import { addedCollection } from '../store/collectionsReducer';
import { notificationSuccess } from '../store/notificationReducer';

export const createCollection = (
  newCollection,
  userId,
  setIsShowCollectionModal,
  isShowCollectionModal
) => {
  return function (dispatch) {
    api
      .post(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/collections/`,
        newCollection
      )
      .then(() => {
        dispatch(addedCollection(newCollection));
        setIsShowCollectionModal(!isShowCollectionModal);
      })
      .catch((error) => {
        const notification_error = {
          isNotification: true,
          type: 'error',
          action: 'error',
          message: error.response.data[0] || error.response.data.priority[0],
        };

        dispatch(notificationSuccess(notification_error));
      });
  };
};
