import { api } from '../api/httpClient';

import { updatedCollection } from '../store/collectionsReducer';
import { notificationSuccess } from '../store/notificationReducer';

const updateCollection = (userId, id, data) => {
  return function (dispatch) {
    api
      .patch(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/collections/${id}/`,
        data
      )
      .then(() => {
        dispatch(updatedCollection(data));
      })
      .catch((error) => {
        dispatch(
          notificationSuccess({
            isNotification: true,
            type: 'error',
            action: 'error',
            message: error.response.data[0] || error.response.data.priority[0],
          })
        );
      });
  };
};

export default updateCollection;
