import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: [],
  loading: true,
  error: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getAuth(state) {
      state.loading = true;
    },
    getAuthSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.auth = action.payload;
    },
    getAuthFailure(state) {
      state.loading = false;
      state.error = true;
      state.auth = [];
    },
  },
});

export const { getAuth, getAuthSuccess, getAuthFailure } = authSlice.actions;
export default authSlice.reducer;
