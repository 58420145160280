import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import styles from './Checkbox.module.scss';

import Icon from '../Icon';

const Checkbox = (props) => {
  return (
    <FormControlLabel
      classes={{
        root: styles['MuiFormControlLabel-root'],
        label: styles['MuiFormControlLabel-label'],
      }}
      {...props}
      control={
        <MuiCheckbox
          classes={{ root: styles['MuiFormControlLabel-root'] }}
          icon={<Icon id="checkbox-frame" width="20" height="20" />}
          checkedIcon={<Icon id="checkbox-checked" width="20" height="20" />}
        />
      }
    />
  );
};

export default Checkbox;
