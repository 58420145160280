import { api } from '../api/httpClient';

import { updatedCollectionPriority } from '../store/collectionsReducer';

const updatePriority = (userId, payload) => {
  return function (dispatch) {
    api
      .post(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/collections/priorities/`,
        { data: payload }
      )
      .then(() => {
        dispatch(updatedCollectionPriority(payload));
      });
  };
};

export default updatePriority;
