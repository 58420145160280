import {
  getGroupsListSuccess,
  getGroupsListFailure,
} from '../store/groupsReducer';
import { updateGroups } from '../store/treeReducer';
import { api } from '../api/httpClient';

export const fetchGroupsList = (user_id) => {
  return function (dispatch) {
    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/ad_groups/`
      )
      .then((response) => {
        // TODO: move group list entity to the tree reducer and remove the group list reducer
        dispatch(getGroupsListSuccess(response.data.results));
        dispatch(updateGroups(response.data.results));
      })
      .catch(() => {
        dispatch(getGroupsListFailure());
      });
  };
};
